export default {
  BUTTON_PRIMARY_CLASS: 'cm_button cm_button__primary',
  BUTTON_SECONDARY_CLASS: 'cm_button cm_button__secondary',

  SEARCH_BOX_CLASS: 'search__form',
  SEARCH_BOX_BUTTON_CLASS: 'icon-search',
  SEARCH_BOX_DIALOG_OPENING_ICON_CLASS: 'icon-search',

  FACET_BAR_WHEELS_TIRES_TITLE_CLASS: 'facettitle',

  YOU_MAY_ALSO_LIKE: 'You may also like',

  SELECT_YOUR_VEHICLE_BLOCK: '',
  GARAGE_CLASS: 'mini_cart',
  GARAGE_SIZE: '<span class="garage-size cart_count" key="garage-size">{{size}}</span>',
  FITMENT_TABLE_VEHICLE_SPECIFIC: 'This Product Fits:',

  VERIFY_FITMENT_NOT_FIT: 'This product DOES NOT Fit or may be too aggressive for your',
  // VERIFY_FITMENT_NOT_FIT:
  //   'This {this.fitmentSearchTitle === "Tires" ? "tire" : this.fitmentSearchTitle === "Wheels" ? "wheel" : "product" } DOES NOT Fit or may be too aggressive for your',

  VERIFY_FITMENT_AGGRESSIVE_FITS: 'This wheel will fit, but will be considered aggressive',

  VERIFY_FITMENT_ICON: `
    <rt-virtual rt-if="this.fits === 'yes' || this.fits === 'universal'" template="icons/check" />
    <rt-virtual rt-if="this.fits === 'no'" template="icons/times" />
    <rt-virtual rt-if="this.fits === 'unknown'" template="icons/questionmark" />
    <rt-virtual rt-if="this.fits === 'aggressive'" template="icons/exclamation" />`,
};
