//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-384:_2608,_4952,_2112,_4880,_1686,_856,_3016,_1232;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-384')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-384', "_2608,_4952,_2112,_4880,_1686,_856,_3016,_1232");
        }
      }catch (ex) {
        console.error(ex);
      }