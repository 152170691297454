const baseFitmentSearchFields = ['Year', 'Make', 'Model', 'Submodel', 'Option'];
const extraFitmentSearchFields = [];
const fitmentSearchFields = [...baseFitmentSearchFields, ...extraFitmentSearchFields];
const wheelFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern', 'wheel_load_rating'];
const tiresFields = ['tire_width', 'tire_aspect', 'wheel_diameter', 'tire_load_rating', 'tire_speed_rating'];
const ignoreFields = [...wheelFields, ...tiresFields, 'Universal', 'YearsPanel', 'tire_size', 'Fitment'];

const categorySelectionPageUrl = '/collections';
const brandSelectionPageUrl = '/pages/brands';
const searchPageUrl = '/search';

const searchWheelsByVehiclePageUrl = '/pages/search-wheels-by-vehicle';
const searchWheelsBySizePageUrl = '/pages/search-wheels-by-size';
const searchWheelsByBrandPageUrl = '/pages/search-wheels-by-brand';
const wheelsCollectionUrl = '/collections/wheels';

const searchTiresByVehiclePageUrl = '/pages/search-tires-by-vehicle';
const searchTiresBySizePageUrl = '/pages/search-tires-by-size';
const searchTiresByBrandPageUrl = '/pages/search-tires-by-brand';
const tiresCollectionUrl = '/collections/tires';

const mountedPackagesUrl = '/collections/shop-mounted-packages';

const wheelRequestPanelConfig = {
  isAutoRedirectDisabled: true,
  redirectUrl: wheelsCollectionUrl,
  selectFields: ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'],
};
const tireRequestPanelConfig = {
  isAutoRedirectDisabled: true,
  redirectUrl: tiresCollectionUrl,
  selectFields: ['tire_width', 'tire_aspect', 'wheel_diameter'],
};

let mayShowAggressiveFitmentsDialog = false;

const openAggressiveFitmentsDialog = () =>
  window.document.querySelector('#cm_AggressiveFitmentsDialogButton .cm_button')?.click();

globalThis.Convermax.closeAggressiveFitmentsDialog = () => {
  window.document.querySelector('#cm_AggressiveFitmentsDialog .close')?.click();
  window.document.querySelector('.cm_field-AggressiveFitments .facetdiv')?.click();
};

const updateCallback = () => {
  const searchRequest = window.Convermax.getSearchRequest();

  const isAggressiveFitmentsRequest = searchRequest.selection.some((s) => s.field === 'AggressiveFitments');

  if (
    isAggressiveFitmentsRequest &&
    mayShowAggressiveFitmentsDialog &&
    window.localStorage.getItem('cm_staggered-step') !== 'rear'
  ) {
    openAggressiveFitmentsDialog();
    mayShowAggressiveFitmentsDialog = false;
  } else if (!isAggressiveFitmentsRequest) {
    mayShowAggressiveFitmentsDialog = true;
  }

  if (
    searchRequest.selection.some((s) => ['WheelProductID', 'TireProductID'].includes(s.field) && !!s.term)
  ) {
    window.localStorage.setItem('hide-package-button-for-next', true);
  }
};

const getLocalPreselection = (pageType, defaults) => {
  const { location } = window;

  if (location.pathname === searchWheelsByBrandPageUrl) {
    return [{ field: 'is_wheel_vendor', term: 'True' }];
  }
  if (location.pathname === searchTiresByBrandPageUrl) {
    return [{ field: 'is_tire_vendor', term: 'True' }];
  }

  return defaults.getter(pageType, defaults);
};

function responseHandler(response) {
  if (response.Messages?.includes('ShowPackageButton')) {
    const packageButton = window.document.querySelector('.shopify-product-form .package-button');

    if (packageButton && !JSON.parse(localStorage.getItem('hide-package-button-for-next'))) {
      packageButton.style.display = 'block';
    }
  }

  if (response.Messages?.includes('ShowTPMSBanner')) {
    const tpmsBannerElem = window.document.querySelector('#cm_tpms_banner');
    if (tpmsBannerElem) {
      tpmsBannerElem.style.display = 'block';
    }
  }

  if (response.Messages?.includes('ShowTeslaTPMSBanner')) {
    const teslaTpmsBannerElem = window.document.querySelector('#cm_tesla_tpms_banner');
    if (teslaTpmsBannerElem) {
      teslaTpmsBannerElem.style.display = 'block';
    }
  }

  if (response.Messages?.includes('CartContainsAggressiveWheels')) {
    // Add to option to hidden input for integrating in order details
    const aggressiveInputElem = window.document.querySelector('#cm_cartvehicle-input-aggressive');
    aggressiveInputElem.value =
      'Customer selected aggressive fitment wheels and accepts final sale / no returns policy';

    // Display wording about aggressive fitments
    window.document.querySelector('.subtotal .cm_aggressive-label').style.display = 'block';
    const aggressiveWheelIds = response.Extra.AggressiveWheelIds;
    const cartItems = window.document.querySelectorAll('.cart__item .cm_aggressive-label');

    cartItems.forEach((elem) => {
      if (aggressiveWheelIds?.includes(elem.getAttribute('data-product-id'))) {
        elem.style.display = 'block';
      }
    });
  }

  return response;
}

function getFitmentSearchTitle() {
  const { pageType } = window.ShopifyAnalytics.meta.page;

  const title = window.document
    .querySelector('body.collection .one-half.column.medium-down--one-whole h1')
    ?.textContent.trim();

  return pageType === 'collection' || pageType === 'vendors'
    ? title
    : pageType === 'product'
      ? window.ShopifyAnalytics.meta.product.type
      : 'Parts';
}

function handleCartVehicleLabel(vehicle) {
  const cartVehicleLabel = window.document.querySelector('#cm_cart-vehicle-label');
  if (cartVehicleLabel) {
    const vehicleLabel = fitmentSearchFields
      .map((field) => vehicle[field])
      .filter(Boolean)
      .join(' ');

    cartVehicleLabel.querySelector('.vehicle').textContent = vehicleLabel;
    cartVehicleLabel.removeAttribute('style');
  }
}

function handleTireUpsell(vehicleSearchParams) {
  const { cartProductIds } = window.Convermax;

  if (cartProductIds) {
    cartProductIds.forEach((id) => {
      const tireSearchButton = window.document.querySelector(`.cart__item[data-product-id="${id}"] p.meta a`);

      if (tireSearchButton) {
        tireSearchButton.href = `/collections/tires/?WheelProductID/${id}/${vehicleSearchParams}`;
      }
    });
  }
}

function updateOrderVehicleAttributeInputs(vehicle) {
  const vehicleInputElem = window.document.querySelector('#cm_cartvehicle-input');
  if (vehicleInputElem) {
    vehicleInputElem.value = '';
  }

  fitmentSearchFields.forEach((field) => {
    const inputElem = window.document.querySelector(`#cm_cartvehicle-input-${field.toLowerCase()}`);
    if (inputElem) {
      inputElem.value = vehicle[field] || '';
    }
  });
}

function handleCustomVehicleSelection(vehicleStr) {
  const cartVehicleLabel = window.document.querySelector('#cm_cart-vehicle-label');
  if (cartVehicleLabel) {
    cartVehicleLabel.querySelector('.vehicle').textContent = vehicleStr;
    cartVehicleLabel.removeAttribute('style');
  }

  const vehicleInputElem = window.document.querySelector('#cm_cartvehicle-input');
  if (vehicleInputElem) {
    vehicleInputElem.value = vehicleStr;
  }
}

globalThis.Convermax.onCartDialogChange = ({ target, currentTarget }) => {
  if (
    target.name === 'specify-vehicle-checkbox' &&
    !target.closest('.cm_checkbox').classList.contains('active')
  ) {
    const currentActiveElems = [...currentTarget.querySelectorAll('.active')];
    const newActiveElems = [...currentTarget.querySelectorAll(`.cm_${target.value}`)];
    [...currentActiveElems, ...newActiveElems].forEach((elem) => elem.classList.toggle('active'));
  }
};

globalThis.Convermax.onCartDialogSubmit = (e) => {
  e.preventDefault();

  const clickableVehicleWidgetGoBtn = window.document.querySelector(
    '.cm_select-vehicle .cm_vehicle-widget_button__go:not([disabled])',
  );
  if (clickableVehicleWidgetGoBtn) {
    clickableVehicleWidgetGoBtn.click();
  }

  if (!window.Convermax.isVehicleSelected()) {
    const formValues = Object.fromEntries(new FormData(e.target));

    if (
      formValues['specify-vehicle-checkbox'] === 'type-vehicle' &&
      formValues['custom-vehicle-input'] !== ''
    ) {
      updateOrderVehicleAttributeInputs({}); // to clean up ymm inputs
      handleCustomVehicleSelection(formValues['custom-vehicle-input']);
      localStorage.setItem('cm_customer-vehicle', formValues['custom-vehicle-input']);
    } else if (formValues['specify-vehicle-checkbox'] === 'skip-vehicle') {
      updateOrderVehicleAttributeInputs({}); // to clean up ymm inputs
      handleCustomVehicleSelection('Opted Out (no fitment guarantee)');
      localStorage.setItem('cm_customer-vehicle', 'opted-out');
    } else {
      window.document.querySelector('.cm_validation-error').textContent = 'Please select one of the options';
      return;
    }
  }

  if (window.document.documentElement.classList.contains('page--thank-you')) {
    let vehicle;
    if (window.Convermax.isVehicleSelected()) {
      vehicle = {
        ...window.Convermax.getVehicle(),
      };
    } else {
      vehicle = {
        'Customer Vehicle': localStorage.getItem('cm_customer-vehicle'),
      };
    }

    updateOrderData(vehicle);
  } else if (window.document.documentElement.classList.contains('page--checkout')) {
    updateCheckoutData();
  }

  window.document.querySelector('#cm_CartPageVehicleDialog .close')?.click();
};

async function updateOrderData(vehicle) {
  const orderId = window.Shopify.checkout?.order_id;
  const { apiHost } = window.Shopify.Checkout;
  if (!orderId) {
    // eslint-disable-next-line no-console
    console.log('User is not Authenticated (orderId is undefined)!');
    return;
  }

  const apiRes = await window.fetch(
    `https://app.convermax.com/shopify/order_data/vehicle?shop=${apiHost}&order_id=${orderId}`,
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(vehicle),
    },
  );

  if (!apiRes.ok) {
    console.error(apiRes);
    return;
  }

  window.Convermax.refreshOrderDataVehicle();
}

function updateCheckoutData() {
  const checkoutForm = document.querySelector('form.edit_checkout');
  const checkoutFormData = new FormData(checkoutForm);

  const formData = new FormData();
  formData.append('_method', checkoutFormData.get('_method'));
  formData.append('authenticity_token', checkoutFormData.get('authenticity_token'));
  formData.append('previous_step', checkoutFormData.get('previous_step'));
  formData.append('step', checkoutFormData.get('step'));
  formData.append('checkout[attributes][Year]', checkoutFormData.get('checkout[attributes][Year]'));
  formData.append('checkout[attributes][Make]', checkoutFormData.get('checkout[attributes][Make]'));
  formData.append('checkout[attributes][Model]', checkoutFormData.get('checkout[attributes][Model]'));
  formData.append('checkout[attributes][Submodel]', checkoutFormData.get('checkout[attributes][Submodel]'));
  formData.append('checkout[attributes][Option]', checkoutFormData.get('checkout[attributes][Option]'));
  formData.append(
    'checkout[attributes][Customer Vehicle]',
    checkoutFormData.get('checkout[attributes][Customer Vehicle]'),
  );

  const request = new XMLHttpRequest();
  request.open('POST', checkoutForm.getAttribute('action'));
  request.send(formData);
}

globalThis.getVehicleSearchQuery = () => {
  const vehicleSearchSelection = window.Convermax.getVehicleSearchParams();
  return vehicleSearchSelection ? `?${vehicleSearchSelection}` : '';
};

function InitFunc() {
  if (window.ShopifyAnalytics?.meta.page.pageType !== 'product') {
    localStorage.removeItem('hide-package-button-for-next');
  }
}

function AfterInit() {
  const isCartOrCheckout =
    window.document.body.classList.contains('cart') ||
    window.document.documentElement.classList.contains('page--checkout');

  if (!isCartOrCheckout) {
    localStorage.removeItem('cm_customer-vehicle');
  }

  const currentVehicle = window.Convermax.getVehicle();
  if (Object.keys(currentVehicle).length) {
    handleCartVehicleLabel(currentVehicle);
    handleTireUpsell(window.Convermax.getVehicleSearchParams());
  }

  window.Convermax.openCartPageVehicleDialog = () =>
    window.document.querySelector('#cm_CartPageVehicleDialogButton .cm_button')?.click();

  if (isCartOrCheckout && !window.Convermax.isVehicleSelected()) {
    const customerVehicle = localStorage.getItem('cm_customer-vehicle');
    if (customerVehicle) {
      if (customerVehicle === 'opted-out') {
        handleCustomVehicleSelection('Opted Out (no fitment guarantee)');
      } else {
        handleCustomVehicleSelection(customerVehicle);
      }
    } else {
      handleCustomVehicleSelection('Dialog was skipped');
      window.Convermax.openCartPageVehicleDialog();
    }
  }
}

window.Convermax.handlePaginationClick = (e) => {
  if (!e.target.classList.contains('active') && !e.target.closest('.active') && window.innerWidth > 800) {
    window.scrollTo(0, 0);
  }
};

const onVehicleSelected = (currentVehicle) => {
  handleCartVehicleLabel(currentVehicle);
  handleTireUpsell(window.Convermax.getVehicleSearchParams());
  updateOrderVehicleAttributeInputs(currentVehicle);
  window.document.querySelector('#cm_CartPageVehicleDialog .close')?.click();
};

export default {
  platform: 'shopify',
  InitFunc,
  AfterInit,
  searchPageUrl,
  getLocalPreselection,
  responseHandler,
  disableRequestScroll: window.innerWidth > 800,
  pagination: {
    centralRange: 1,
  },
  SearchRequestDefaults: {
    pageSize: 36,
  },
  getSearchRequestDefaults: (pageType) =>
    pageType === 'cart' && window.Convermax.cartProductIds
      ? { extra: { wheelsIds: window.Convermax.cartProductIds, pageType } }
      : { extra: { pageType } },
  product: {
    searchResultsImageWidth: 270,
    searchResultsImageHeight: 270,
  },
  page: {
    getPageType: (defaults) =>
      window.document.body.classList.contains('cart')
        ? 'cart'
        : window.document.documentElement.classList.contains('page--checkout')
          ? 'checkout'
          : window.document.querySelector('#cm_vehicle-widget__landing')
            ? 'unknown'
            : defaults.getter(),
  },
  fitmentSearch: {
    baseFields: baseFitmentSearchFields,
    extraFields: extraFitmentSearchFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'vendor' },
      { pathname: searchWheelsByBrandPageUrl, field: 'vendor' },
      { pathname: searchTiresByBrandPageUrl, field: 'vendor' },
    ],
    categorySelectionPageRedirect: true,
    isAutoRedirectDisabled: true,
    isAutoVehicleSelectionDisabled: true,
    isVehicleSelectionIsolated: true,
    getFitmentSearchTitle,
    onVehicleSelected,
  },
  facets: {
    rangedFacet: [
      { fields: ['wheel_offset'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1' },
      { fields: ['price'], name: 'priceFacet' },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: ['search', 'collection']
        .map((className) => `body.${className} .content .content-wrapper`)
        .join(),
      template: 'SearchPage',
      visibleIf: () => window.location.pathname !== categorySelectionPageUrl,
    },
    {
      name: 'SearchLandingPage',
      type: 'SearchPage',
      location: {
        insertAfter: '#cm_vehicle-widget__landing',
        class: 'cm_search-landing-page',
      },
      template: 'SearchPage',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Winter Bestsellers',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
        'collection_sort': 'Featured',
      },
      sortSelectClass: 'text-secondary py-1 pl-2 pr-4',
    },
    {
      name: 'SearchResult',
      updateCallback,
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields: [...ignoreFields, 'winter_season'],
    },
    {
      name: 'FacetPanel',
      ignoreFields,
      initCollapsed: true,
      valuesCountToShowFilterInput: 10,
    },
    {
      name: 'UpsizeDownsizeFacetPanel',
      type: 'FacetPanel',
      fields: ['wheel_diameter', 'tire_size'],
      showAllAlways: true,
      disableCollapse: true,
    },
    {
      name: 'YearsPanel',
      type: 'FacetTiles',
      location: {
        insertBefore: '#cm_vehicle-widget__landing',
        class: 'cm_years-panel__container',
      },
      facetField: 'YearsPanel',
      template: 'facetTiles/yearsTilesContainer',
      visibleIf: () => !window.location.search.includes('?Year/'),
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelFields,
      minFacetCount: 2,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
      minFacetCount: 2,
    },
    {
      name: 'WheelWizVehicleInfo',
    },
    {
      name: 'SearchBox',
      location: {
        selector: '.header .search-container',
        class: 'cm_search-box-root__header',
      },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '#header .icon-search',
        class: 'cm_search-box-dialog-button_container',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'Garage',
      location: { insertBefore: '.header .cart-container', class: 'cart-container' },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: { insertBefore: '#header .cart-container', class: 'cart-container' },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'HomeVehicleWidget_tab',
      type: 'VehicleWidget',
      location: {
        selector: '.cm_tab-content.cm_vehicle-search .widget-ymm-bar',
        class: 'cm_vehicle-widget__tab',
      },
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'HeaderVehicleWidget', // to send request with ymm to get TPMS info on the cart page
      type: 'VehicleWidget',
      location: {
        lastChildOf: 'body.cart .footer',
        class: 'cm_vehicle-widget__header cm_hide',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
    },
    {
      name: 'WheelPanel_tab',
      type: 'RequestPanel',
      location: '.cm_tab-content.cm_wheel-search .widget-wheel-bar',
      template: 'SearchPanelDropdowns',
      needInitRequest: true,
      ...wheelRequestPanelConfig,
    },
    {
      name: 'TirePanel_tab',
      type: 'RequestPanel',
      location: '.cm_tab-content.cm_tire-search .widget-tire-bar',
      template: 'SearchPanelDropdowns',
      needInitRequest: true,
      ...tireRequestPanelConfig,
    },
    {
      name: 'SearchBox_tab',
      type: 'SearchBoxDialogButton',
      location: {
        selector: '.cm_tab-content.cm_brand-search .widget-brand-bar',
        class: 'cm_search-box-root__tab',
      },
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      visibleIf: { selector: ['#cm_vehicle-widget__landing', 'body.search', 'body.collection'] },
    },
    {
      name: 'HeaderVehicleWidget_SearchByVehicle',
      type: 'VehicleWidget',
      location: {
        selector: '#cm_search-by-vehicle',
        class: 'cm_vehicle-widget__search-by-vehicle',
      },
      template: 'fitmentSearch/homeVehicleWidget',
      redirectUrl:
        window.location.pathname === searchWheelsByVehiclePageUrl
          ? wheelsCollectionUrl
          : window.location.pathname === searchTiresByVehiclePageUrl
            ? tiresCollectionUrl
            : null,
    },
    {
      name: 'HeaderVehicleWidget_landing',
      type: 'VehicleWidget',
      location: {
        selector: '#cm_vehicle-widget__landing',
        class: 'cm_vehicle-widget__landing',
      },
      template: 'fitmentSearch/homeVehicleWidget',
      redirectUrl: '/search',
    },
    {
      name: 'SearchWheelsBySize',
      type: 'RequestPanel',
      location: '#cm_search-wheels-by-size',
      template: 'SearchPanelDropdowns',
      needInitRequest: true,
      ...wheelRequestPanelConfig,
    },
    {
      name: 'SearchTiresBySize',
      type: 'RequestPanel',
      location: '#cm_search-tires-by-size',
      template: 'SearchPanelDropdowns',
      needInitRequest: true,
      ...tireRequestPanelConfig,
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
      doNotRedirectOnVehicleSelect: true,
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: '#cm_verify-fitment',
      template: 'fitmentSearch/verifyFitment',
      columnBreakpoint: 500,
    },
    {
      name: 'FitmentTable',
      location: {
        lastChildOf: 'body.product .shopify-section--product-description',
        class: 'cm_fitment-table_container',
      },
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '.content .content-wrapper',
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: () => window.location.pathname === categorySelectionPageUrl,
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: {
        selector: '.content .column.rte',
        class: 'cm_vehicle-categories__brands',
      },
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: { urls: [brandSelectionPageUrl, searchWheelsByBrandPageUrl, searchTiresByBrandPageUrl] },
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields: ['RimDiameter', 'Universal'],
      valuesCountToShowFilterInput: 10,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
    {
      name: 'AggressiveFitmentsDialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/aggressiveFitmentsDialog',
      closeOnClickOutside: false,
    },
    {
      name: 'AggressiveFitmentsDialogButton',
      type: 'DialogButton',
      location: {
        lastChildOf: 'body.search, body.collection',
        class: 'cm_hide',
      },
      template: 'fitmentSearch/customDialogButton',
      dialogName: 'AggressiveFitmentsDialog',
    },
    {
      name: 'CartPageVehicleDialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/cartPageVehicleDialog',
      closeOnClickOutside: false,
    },
    {
      name: 'CartPageVehicleDialogButton',
      type: 'DialogButton',
      location: {
        lastChildOf: 'body.cart, .page--checkout body',
        class: 'cm_hide',
      },
      template: 'fitmentSearch/customDialogButton',
      dialogName: 'CartPageVehicleDialog',
      onClick: () => {
        window.Convermax.discardVehicle();
        updateOrderVehicleAttributeInputs({});
      },
      metadata: { vehicleWidgetIncluded: true },
    },
  ],
};
